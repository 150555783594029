<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-row justify="center">
    <v-snackbar top
                v-model="snackbar" :color="color"
                timeout="2000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" class="px-10">
      <v-card-title class="text-center">
        <span class="headline">Update exam</span>
      </v-card-title>
      <v-card-text>
        <form @submit.prevent="takeAction">
          <validation-observer
              ref="observer"
              v-slot="{ invalid }"
          >
            <v-row>
              <v-col cols="6">
                <v-switch
                    v-model="is_paid"
                    label="Paid"
                    color="primary"
                ></v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch
                    v-model="strict"
                    label="Strict"
                    color="primary"
                ></v-switch>
              </v-col>

              <v-col
                  class="d-flex"
                  cols="12"
              >
                <v-select
                    :items="modes"
                    item-text='name'
                    item-value="value"
                    label="Choose a mode"
                    v-model="mode"
                ></v-select>
              </v-col>

              <v-col
                  v-if="!videoId"
                  class="d-flex"
                  cols="12"
              >
                <v-select
                    :items="course.topics"
                    item-text='name'
                    item-value="id"
                    label="Select topic"
                    v-model="topic_id"
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
                  :md="total_retry > 1 ?'6' : '12'"
              >
                <v-text-field
                    label="Total retry"
                    v-model="total_retry"
                    type="number"
                    :min="1"
                />
              </v-col>

              <v-col
                  v-if="total_retry > 1"
                  cols="12"
                  md="6"
              >
                <v-text-field
                    label="Total questions"
                    v-model="total_questions"
                    type="number"
                    :min="1"
                />
              </v-col>
              <v-col
                  cols="12"
              >
                <validation-provider
                    v-slot="{ errors }"
                    name="Title"
                    rules="required"
                >
                  <v-text-field
                      v-model="title"
                      :error-messages="errors"
                      label="Exam title"
                      required
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col
                  cols="12"
                  v-if="mode==='group_exam'"
              >
                <v-text-field
                    v-model="total_section"
                    label="Total section"
                    required>
                </v-text-field>
              </v-col>

              <v-col
                  cols="12"
              >
                <validation-provider
                    v-slot="{ errors }"
                    name="QuestionMark"
                    rules="required"
                >
                  <v-text-field
                      v-model="per_question_mark"
                      :error-messages="errors"
                      label="Per question mark"
                      required
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col
                  cols="12"
              >
                <validation-provider
                    v-slot="{ errors }"
                    name="NegativeMark"
                    rules="required"
                >
                  <v-text-field
                      v-model="negative_mark"
                      :error-messages="errors"
                      label="Per question negative mark"
                      required
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col
                  cols="12"
              >
                <validation-provider
                    v-slot="{ errors }"
                    name="PassMark"
                    rules="required"
                >
                  <v-text-field
                      v-model="pass_mark"
                      :error-messages="errors"
                      label="Pass mark"
                      required
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col
                  v-if="mode==='exam' || mode==='group_exam' || mode === 'competition'"
                  cols="12">
                <v-text-field
                    v-model="duration"
                    label="Duration in minute"
                >
                </v-text-field>
              </v-col>

              <v-col
                  v-if="mode==='exam' && allowDescription"
                  cols="12"
              >
<!--                <quill-editor-->
<!--                    :options="{placeholder: 'Enter exam description'}"-->
<!--                    v-model="description"-->
<!--                ></quill-editor>-->
                <tiptap-vuetify
                    v-model="description"
                    :extensions="extensions"
                    placeholder="Enter exam description"
                >
                </tiptap-vuetify>
              </v-col>

              <v-col
                  cols="12"
                  sm="12">
                <v-datetime-picker
                    ref="availableAtRef"
                    v-model="available_at"
                    :text-field-props="{prependIcon: 'mdi-calendar'}"
                    label="Available at"
                    time-format="hh:mm:ssa"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>

              <v-col
                  v-if="mode==='exam' || mode==='group_exam' || mode === 'competition'"
                  cols="12"
                  sm="12">
                <v-datetime-picker
                    ref="endsAtRef"
                    v-model="ends_at"
                    :text-field-props="{prependIcon: 'mdi-calendar'}"
                    label="Answer available at"
                    time-format="hh:mm:ssa"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>

              <v-btn
                  class="mr-4"
                  type="submit"
                  :disabled="invalid"
                  :loading="loading"
              >
                {{ editMode ? 'update' : 'submit' }}
              </v-btn>

              <v-btn @click="clear">
                clear
              </v-btn>

            </v-row>
          </validation-observer>
        </form>
      </v-card-text>
    </v-col>
  </v-row>
</template>
<script>
import {required} from 'vee-validate/dist/rules'
import axios from 'axios'
import {extend, ValidationObserver, ValidationProvider, setInteractionMode} from 'vee-validate'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// import {quillEditor} from 'vue-quill-editor'
import moment from 'moment'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'

export default {
  props: ['course','videoId','examId'],
  components: {
    ValidationProvider,
    ValidationObserver,
    // quillEditor,
    TiptapVuetify,
  },
  data: () => ({
    allowDescription: false,
    loading: false,
    selectedExam: null,
    editMode: false,
    dialog: false,
    title: '',
    total_section: null,
    description: '',
    topic_id: '',
    snackbar: false,
    text: '',
    is_paid: 0,
    privacy: false,
    available_at: '',
    color: 'blue',
    strict: false,
    duration: '',
    ends_at: '',
    pass_mark: '',
    per_question_mark: 1,
    negative_mark: 0,
    mode: 'exam',
    modes: [{
      name: 'Exam',
      value: 'exam',
    }, {
      name: 'Exam (Subject wise)',
      value: 'group_exam',
    }, {
      name: 'Practice',
      value: 'practice',
    }],
    total_retry: 1,
    total_questions: '',
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Link,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
  }),
  computed: {
    availableAt() {
      return moment(this.available_at).isValid() ? moment(this.available_at).format() : '';
    },
    endsAt() {
      return moment(this.ends_at).isValid() ? moment(this.ends_at).utc('utc').format() : '';
    },
    form: function () {
      let formData = new FormData();
      if (this.course) {
        formData.append('course_id', this.course.id);
      }
      if (this.editMode) {
        formData.append('_method', 'PUT');
      }
      if (!this.videoId){
        formData.append('topic_id', this.topic_id)
      } else {
        formData.append('video_id',this.videoId)
      }
      formData.append('title', this.title)
      formData.append('mode', this.mode)
      formData.append('description', this.description ? this.description : '')
      formData.append('privacy', this.privacy ? 'private' : 'public')
      formData.append('is_paid', this.is_paid ? 1 : 0)
      formData.append('available_at', this.availableAt)
      formData.append('pass_mark', this.pass_mark)
      formData.append('per_question_mark', this.per_question_mark)
      formData.append('negative_mark', this.negative_mark)
      formData.append('duration', this.duration)
      formData.append('ends_at', this.endsAt)
      formData.append('strict', this.strict ? 1 : 0)
      formData.append('total_retry', this.total_retry)
      if (this.total_retry > 1) {
        formData.append('total_questions', this.total_questions)
      }
      if (this.mode === 'group_exam') {
        formData.append('total_section', this.total_section)
      }
      return formData;
    }
  },
  methods: {
    takeAction() {
      this.editMode ? this.update() : this.submit();
    },
    edit(exam) {
      this.editMode = true
      this.title = exam.title
      this.description = exam.description
      this.link = exam.link
      this.topic_id = exam.content ? exam.content.topic_id : null
      this.selectedExam = exam
      this.is_paid = exam.is_paid
      this.total_section = exam.total_section
      this.privacy = exam.content ? exam.content.privacy === 'private' : ''
      this.pass_mark = exam.pass_mark
      this.mode = exam.mode
      this.per_question_mark = exam.per_question_mark
      this.negative_mark = exam.negative_mark
      this.available_at = moment(exam.content ? exam.content.available_at : '').isValid() ? moment(exam.content ? exam.content.available_at : '').toDate() : '';
      this.ends_at = moment(exam.ends_at).isValid() ? moment(exam.ends_at).toDate() : '';
      this.duration = exam.duration;
      this.strict = exam.strict === 1
      this.total_retry = exam.total_retry
      this.total_questions = exam.total_questions
    },
    submit() {
      const url = 'admin/exams';
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'New exam created';
        this.loadExams();
      })
    },
    update() {
      const url = 'admin/exams/' + this.selectedExam.id;
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'Exam updated';
        this.loadExam();
      })
    },
    loadExam() {
      const url = 'admin/exams/' + this.examId || this.$route.params.examId + '?filter=admin';
      axios.get(url).then((response) => {
        this.edit(response.data)
      })
    },
    clear() {
      this.title = '';
      this.description = '';
      this.link = '';
      this.topic_id = '';
      this.selectedExam = null;
      this.available_at = '';
      this.pass_mark = ''
      this.total_section = null
      this.per_question_mark = 1
      this.negative_mark = 0
      this.strict = 0
      this.privacy = false;
      this.total_retry = 1
      this.total_questions = ''
      if (this.mode === 'exam') {
        this.$refs.availableAtRef.clearHandler();
        this.$refs.endsAtRef.clearHandler();
      }
      this.$refs.observer.reset();
      this.duration = '';
    },
  },
  mounted() {
    this.loadExam();
  }
}

setInteractionMode('eager');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})
</script>