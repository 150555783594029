<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
      v-model="dialog"
      max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          :loading="loading"
          :disabled="loading"
          v-bind="attrs"
          v-on="on"
          fab
          small
          color="blue-grey"
          class="ma-2 white--text"
          @click="loader = 'loading3'"
      >
        <v-icon
            dark
        >
          mdi-cloud-upload
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Import MCQ</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
                cols="12"
            >
              <v-file-input
                  clearable
                  truncate-length="25"
                  v-model="file"
                  label="Insert excel file"
              ></v-file-input>
            </v-col>
            <v-col>
              <v-spacer></v-spacer>
              <v-btn
                  class="mr-4"
                  type="submit"
                  @click="submit"
              >
                Upload
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  props: ['exam_id', 'mcq_sub_store_id', 'section_id'],
  data() {
    return {
      loading: false,
      dialog: false,
      file: null,
    }
  },
  methods: {
    submit() {
      this.loading = true;
      this.dialog = false;
      const url = 'admin/mcqs/import';
      let formData = new FormData();
      if (this.file) {
        formData.append('file', this.file);
      }
      if (this.exam_id) {
        formData.append('exam_id', this.exam_id);
      }
      if (this.mcq_sub_store_id) {
        formData.append('mcq_sub_store_id', this.mcq_sub_store_id)
      }
      if (this.section_id) {
        formData.append('section_id', this.section_id)
      }
      axios.post(url, formData).then(() => {
        Swal.fire('Question uploaded successfully');
        this.loading = false
        this.$emit('updated')
        this.file = null
      }).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
        this.loading = false;
      })
    }
  }
}
</script>