<template xmlns:v-katex="http://www.w3.org/1999/xhtml">
  <v-card class="my-2">
    <v-card-text class="py-0">
      <div class="text-center caption" v-if="type === 'search'">
        {{ courseName }}
      </div>
      <v-radio-group
          v-model="answer"
          column
      >
        <v-list-item>
          <v-list-item-content>
            <div v-html="index+1+'. '+mcq.question" v-katex:auto>
            </div>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="editMcq">
              <v-icon color="blue lighten-1">mdi-pencil</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn icon @click="deleteMcq(mcq)">
              <v-icon color="red lighten-1">mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn icon @click="markAsVerified">
              <v-icon :color="verified ? 'primary' : 'secondary'">mdi-check-circle</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action v-if="revision">
            <v-btn color="green" small @click="markAsRevised">
              {{ reviseText }}
            </v-btn>
          </v-list-item-action>
          <v-list-item-action v-if="revision">
            <v-btn :disabled="isDiscarded" color="red" small @click="discardReport">
              {{ discardText }}
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-img
            v-if="mcq.question_photo"
            :aspect-ratio="16/9"
            :width="320"
            :src="mcq.question_photo"
            class="mb-2"
        ></v-img>

        <v-radio
            color="green"
            :value="mcq.a"
            ripple
            v-katex:auto
        >
          <template v-slot:label>
            <div v-katex:auto v-html="mcq.a"></div>
          </template>
        </v-radio>
        <v-radio
            color="green"
            :value="mcq.b"
            ripple
            v-katex:auto
        >
          <template v-slot:label>
            <div v-katex:auto v-html="mcq.b"></div>
          </template>
        </v-radio>
        <v-radio
            color="green"
            :value="mcq.c"
            ripple
            v-katex:auto
        >
          <template v-slot:label>
            <div v-katex:auto v-html="mcq.c"></div>
          </template>
        </v-radio>
        <v-radio
            color="green"
            :value="mcq.d"
            ripple
            v-katex:auto
        >
          <template v-slot:label>
            <div v-katex:auto v-html="mcq.d"></div>
          </template>
        </v-radio>

        <v-radio
            color="green"
            v-if="mcq.e"
            :value="mcq.e"
            ripple
            v-katex:auto
        >
          <template v-slot:label>
            <div v-katex:auto v-html="mcq.e"></div>
          </template>
        </v-radio>

        <v-divider class="mb-2"></v-divider>

        <div v-if="mcq.answer_description" v-html="mcq.answer_description" v-katex:auto></div>
        <v-img
            v-if="mcq.answer_photo"
            :aspect-ratio="16/9"
            :width="320"
            :src="mcq.answer_photo"
            class="mb-2"
        ></v-img>
      </v-radio-group>
    </v-card-text>
    <div v-if="revision">
      <div>
        <v-btn text depressed tile @click="viewComment = !viewComment">
          {{ viewComment ? 'Hide comments' : 'View comments' }}
        </v-btn>
      </div>
    </div>
    <v-card-text v-if="viewComment">
      <div class="text-h4" v-if="viewComment.length < 1">No comment</div>
      <div v-else>
        <v-list two-line>
          <template v-for="(comment, index) in comments">
            <v-list-item
                :key="index"
            >
              <v-list-item-content>
                <v-list-item-title v-html="comment.user.name"></v-list-item-title>
                <v-list-item-subtitle v-html="comment.comment"></v-list-item-subtitle>
                <v-img :src="comment.photo" v-if="comment.photo"/>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import Swal from 'sweetalert2'
import axios from 'axios'

export default {
  props: ['mcq', 'index', 'revision', 'type'],
  data() {
    return {
      viewComment: false,
      isDiscarded: false,
      discardText: 'Discard',
      answer: this.mcq.answer,
      width: 200,
      reviseText: 'Confirm',
      comments: [],
      courseName: '',
      verified: this.mcq.verified,
    }
  },
  methods: {
    editMcq() {
      this.$emit('editMcq', this.mcq)
    },
    deleteMcq(mcq) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/mcqs/' + mcq.id;
          axios.delete(url).then(() => {
            this.$emit('updated');
            Swal.fire(
                'Deleted!',
                '',
                'success'
            )
          }).catch(() => {
            Swal.fire(
                'Failed!',
                'Something went wrong, try again',
                'warning'
            )
          })
        }
      });
    },
    markAsVerified() {
      const url = 'admin/mcqs/' + this.mcq.id;
      let verified = 1
      if (this.mcq.verified === 1) {
        verified = 0
      }
      const data = {
        verified: verified,
      }
      axios.put(url, data).then((response) => {
        this.verified = response.data.verified
        if (response.data.verified)
          Swal.fire('Question is verified')
        else
          Swal.fire('Question is unverified')
      })
    },
    markAsRevised() {
      this.$emit('revised', this.mcq)
      this.reviseText = 'Confirmed'
    },
    discardReport() {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to discard?",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/mcqs/' + this.mcq.id + '?type=report';
          axios.delete(url).then(() => {
            // this.$emit('updated');
            Swal.fire(
                'Discarded!',
                '',
            )
            this.discardText = 'Discarded'
            this.isDiscarded = true
          }).catch(() => {
            Swal.fire(
                'Failed!',
                'Something went wrong, try again',
            )
          })
        }
      });
    },
    loadComments() {
      const url = 'admin/mcq-comments?id=' + this.mcq.id
      axios.get(url).then(response => {
        this.comments = response.data
      })
    },
    getCourseName() {
      const url = 'admin/mcqs/' + this.mcq.id + '?filter=course'
      axios.get(url).then(response => {
        this.courseName = response.data
      })
    }
  },
  mounted() {
    if (this.type === 'search') {
      this.getCourseName()
    }
  },
  watch: {
    viewComment() {
      if (this.viewComment) {
        this.loadComments()
      }
    }
  }
}
</script>