<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-row justify="center">
    <edit-exam v-if="!showEditButton" :course="course" :exam-id="videoExamId || examId" :video-id="videoId"></edit-exam>
    <v-snackbar top
                v-model="snackbar" :color="color"
                timeout="2000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" class="px-10">
      <v-dialog
          v-model="dialog"
          max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              @click="createMcq"
          >
            Create new MCQ
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-center">
            <span class="headline">MCQ Details</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <form @submit.prevent="takeAction">
                <v-row>
                  <!--Question-->
                  <v-col
                      cols="12"
                  >
                    <quill-editor
                        :options="{placeholder: 'Enter question'}"
                        v-model="question"
                    ></quill-editor>
                  </v-col>

                  <!--Question Photo-->
                  <v-col
                      cols="12"
                  >
                    <v-file-input
                        chips
                        label="Choose question photo"
                        v-model="question_photo"
                    ></v-file-input>
                  </v-col>

                  <!--A-->
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="a"
                        label="Option A"
                        required
                    ></v-text-field>
                  </v-col>

                  <!--B-->
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="b"
                        label="Option B"
                        required
                    ></v-text-field>
                  </v-col>

                  <!--C-->
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="c"
                        label="Option C"
                        required
                    ></v-text-field>
                  </v-col>

                  <!--D-->
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="d"
                        label="Option D"
                        required
                    ></v-text-field>
                  </v-col>

                  <!--E-->
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="e"
                        label="Option E"
                    ></v-text-field>
                  </v-col>

                  <!--Answer-->
                  <v-col
                      class="d-flex"
                      cols="12"
                  >
                    <v-select
                        :items="answerOptions"
                        label="Select answer"
                        v-model="answer"
                    ></v-select>
                  </v-col>

                  <!--Answer Description-->
                  <v-col
                      cols="12"
                  >
                    <quill-editor
                        :options="{placeholder: 'Enter answer description'}"
                        v-model="answer_description"
                    ></quill-editor>
                  </v-col>

                  <!--Answer Photo-->
                  <v-col
                      cols="12"
                  >
                    <v-file-input
                        chips
                        label="Choose answer photo"
                        v-model="answer_photo"
                    ></v-file-input>
                  </v-col>


                  <v-btn
                      class="mr-4"
                      type="submit"
                      :loading="loading"
                  >
                    {{ editMode ? 'update' : 'submit' }}
                  </v-btn>
                  <v-btn @click="clear">
                    clear
                  </v-btn>
                </v-row>
              </form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <import-excel :exam_id='examId' @updated="loadMcqs"></import-excel>
      <export-mcq :exam_id="examId" :mcqs="mcqs"></export-mcq>
      <v-btn
          v-if="showEditButton"
          @click="showEditButton = false"
          class="mx-2"
          fab
          dark
          color="cyan"
          small
      >
        <v-icon dark>
          mdi-pencil
        </v-icon>
      </v-btn>
      <v-btn
          v-else
          @click="showEditButton = true"
          class="mx-2"
          fab
          dark
          color="red"
          small
      >
        <v-icon dark>
          mdi-close
        </v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12">
      <show-mcq v-for="(mcq, index) in mcqs" :index="index" :mcq="mcq" :key="mcq.id" @updated="loadMcqs"
                @editMcq="edit">
      </show-mcq>
    </v-col>
  </v-row>
</template>
<script>
import ShowMcq from './ShowMcq.vue'
import axios from 'axios'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'
import EditExam from './EditExam.vue'
import ImportExcel from './ImportExcel.vue'
import ExportMcq from "./ExportMcq";

export default {
  props: ['course','videoExamId','videoId'],
  components: {
    ExportMcq,
    quillEditor,
    ShowMcq,
    EditExam,
    ImportExcel,
  },
  data: () => ({
    showEditButton: true,
    loading: false,
    mcqs: [],
    selectedMcq: null,
    editMode: false,
    dialog: false,
    question: '',
    a: '',
    b: '',
    c: '',
    d: '',
    e: '',
    answer: '',
    answer_description: '',
    question_photo: null,
    answer_photo: null,
    snackbar: false,
    text: '',
    color: 'blue',
  }),
  computed: {
    examId: function () {
      return this.videoExamId ? this.videoExamId : this.$route.params.examId;
    },
    form: function () {
      let formData = new FormData();
      formData.append('exam_id', this.videoExamId || this.$route.params.examId);
      if (this.editMode) {
        formData.append('_method', 'PUT');
      }
      if (this.question) {
        formData.append('question', this.question);
      }
      if (this.a) {
        formData.append('a', this.a);
      }
      if (this.b) {
        formData.append('b', this.b);
      }
      if (this.c) {
        formData.append('c', this.c);
      }
      if (this.d) {
        formData.append('d', this.d);
      }
      if (this.e) {
        formData.append('e', this.e)
      }
      if (this.answer) {
        formData.append('answer', this.answer);
      }
      if (this.answer_description) {
        formData.append('answer_description', this.answer_description);
      }
      if (this.question_photo) {
        formData.append('question_photo', this.question_photo);
      }
      if (this.answer_photo) {
        formData.append('answer_photo', this.answer_photo);
      }
      return formData;
    },
    answerOptions: function () {
      let options = [this.a, this.b, this.c, this.d, this.e];
      return options.filter(function (el) {
        return el !== '';
      });
    }
  },
  methods: {
    takeAction() {
      this.editMode ? this.update() : this.submit();
    },
    createMcq() {
      this.editMode = false;
      this.selectedMcq = null;
      this.question = '';
      this.a = '';
      this.b = '';
      this.c = '';
      this.d = '';
      this.e = '';
      this.answer = '';
      this.answer_description = '';
      this.question_photo = null;
      this.answer_photo = null;
    },
    edit(mcq) {
      this.selectedMcq = mcq;
      this.dialog = true;
      this.editMode = true;
      this.question = mcq.question;
      this.a = mcq.a;
      this.b = mcq.b;
      this.c = mcq.c;
      this.d = mcq.d;
      this.e = mcq.e ? mcq.e : ''
      this.answer = mcq.answer;
      this.answer_description = mcq.answer_description;
      this.question_photo = null;
      this.answer_photo = null;
    },
    submit() {
      const url = 'admin/mcqs';
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'New mcq created';
        this.loadMcqs();
        this.dialog = false;
      })
    },
    update() {
      const url = 'admin/mcqs/' + this.selectedMcq.id;
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'Mcq updated';
        this.loadMcqs();
        this.dialog = false;
      })
    },
    loadMcqs() {
      const id = this.videoExamId || this.$route.params.examId
      const url = 'admin/mcqs?exam=' + id
      axios.get(url).then((response) => {
        this.mcqs = response.data;
      })
    },
    clear() {
      this.editMode = false;
      this.question = '';
      this.a = '';
      this.b = '';
      this.c = '';
      this.d = '';
      this.e = ''
      this.answer = '';
      this.answer_description = '';
      this.question_photo = null;
      this.answer_photo = null;
      this.selectedMcq = null;
    },
  },
  mounted() {
    this.loadMcqs();
  }
}
</script>