<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-row justify="center">
    <v-snackbar top
                v-model="snackbar" :color="color"
                timeout="2000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" class="px-10">
      <v-card-title class="text-center">
        <span class="headline">Insert a new exam</span>
      </v-card-title>
      <v-card-text>
        <form @submit.prevent="takeAction">
          <validation-observer
              ref="observer"
              v-slot="{ invalid }"
          >
            <v-row>
              <v-col cols="6">
                <v-switch
                    v-model="is_paid"
                    label="Paid"
                    color="primary"
                ></v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch
                    v-model="strict"
                    label="Strict"
                    color="primary"
                ></v-switch>
              </v-col>
              <v-col
                  class="d-flex"
                  cols="12"
              >
                <v-select
                    :items="modes"
                    item-text='name'
                    item-value="value"
                    label="Choose a mode"
                    v-model="mode"
                ></v-select>
              </v-col>
              <v-col
                  v-if="!videoId"
                  class="d-flex"
                  cols="12"
              >
                <v-select
                    :items="course.topics"
                    item-text='name'
                    item-value="id"
                    label="Select topic"
                    v-model="topic_id"
                ></v-select>
              </v-col>

              <v-col
                  cols="12"
                  :md="total_retry > 1 ?'6' : '12'"
              >
                <v-text-field
                    label="Total retry"
                    v-model="total_retry"
                    type="number"
                    :min="1"
                />
              </v-col>

              <v-col
                  v-if="total_retry > 1"
                  cols="12"
                  md="6"
              >
                <v-text-field
                    label="Total questions"
                    v-model="total_questions"
                    type="number"
                    :min="1"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <validation-provider
                    v-slot="{ errors }"
                    name="Title"
                    rules="required"
                >
                  <v-text-field
                      v-model="title"
                      :error-messages="errors"
                      label="Exam title"
                      required
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col v-if="mode==='group_exam'">
                <v-text-field
                    v-model="total_section"
                    label="Total section"
                    required>
                </v-text-field>
              </v-col>

              <v-col
                  cols="12"
              >
                <validation-provider
                    v-slot="{ errors }"
                    name="QuestionMark"
                    rules="required"
                >
                  <v-text-field
                      v-model="per_question_mark"
                      :error-messages="errors"
                      label="Per question mark"
                      required
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col
                  cols="12"
              >
                <validation-provider
                    v-slot="{ errors }"
                    name="NegativeMark"
                    rules="required"
                >
                  <v-text-field
                      v-model="negative_mark"
                      :error-messages="errors"
                      label="Per question negative mark"
                      required
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col
                  cols="12"
              >
                <validation-provider
                    v-slot="{ errors }"
                    name="PassMark"
                    rules="required"
                >
                  <v-text-field
                      v-model="pass_mark"
                      :error-messages="errors"
                      label="Pass mark"
                      required
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col
                  v-if="mode==='exam' || mode==='group_exam' || mode === 'competition'"
                  cols="12">
                <v-text-field
                    v-model="duration"
                    label="Duration in minute"
                >
                </v-text-field>
              </v-col>

              <v-col
                  v-if="allowDescription"
                  cols="12"
              >
                <!--                <quill-editor-->
                <!--                    :options="{placeholder: 'Enter exam description'}"-->
                <!--                    v-model="description"-->
                <!--                ></quill-editor>-->
                <tiptap-vuetify
                    v-model="description"
                    :extensions="extensions"
                    placeholder="Enter exam description"
                >
                </tiptap-vuetify>
              </v-col>

              <v-col
                  cols="12"
                  sm="12">
                <v-datetime-picker
                    ref="availableAtRef"
                    v-model="available_at"
                    :text-field-props="{prependIcon: 'mdi-calendar'}"
                    label="Available at"
                    time-format="hh:mm:ssa"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>

              <v-col
                  v-if="mode==='exam' || mode==='group_exam' || mode === 'competition'"
                  cols="12"
                  sm="12">
                <v-datetime-picker
                    ref="endsAtRef"
                    v-model="ends_at"
                    :text-field-props="{prependIcon: 'mdi-calendar'}"
                    label="Answer available at"
                    time-format="hh:mm:ssa"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>

              <v-btn
                  class="mr-4"
                  type="submit"
                  :disabled="invalid"
                  :loading="loading"
              >
                {{ editMode ? 'update' : 'submit' }}
              </v-btn>

              <v-btn @click="clear">
                clear
              </v-btn>

            </v-row>
          </validation-observer>
        </form>
      </v-card-text>
    </v-col>
  </v-row>
</template>
<script>
import {required} from 'vee-validate/dist/rules'
import axios from 'axios'
import {extend, ValidationObserver, ValidationProvider, setInteractionMode} from 'vee-validate'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// import {quillEditor} from 'vue-quill-editor'
import moment from 'moment'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'

export default {
  props: ['course','videoId'],
  components: {
    ValidationProvider,
    ValidationObserver,
    // quillEditor,
    TiptapVuetify,
  },
  data: () => ({
    allowDescription: true,
    loading: false,
    selectedExam: null,
    editMode: false,
    dialog: false,
    title: '',
    description: '',
    topic_id: '',
    snackbar: false,
    total_section: null,
    text: '',
    color: 'blue',
    mode: 'exam',
    modes: [{
      name: 'Exam',
      value: 'exam',
    }, {
      name: 'Exam (Subject wise)',
      value: 'group_exam',
    }, {
      name: 'Practice',
      value: 'practice',
    }],
    is_paid: 0,
    privacy: false,
    available_at: '',
    ends_at: '',
    duration: '',
    per_question_mark: 1,
    negative_mark: 0,
    pass_mark: '',
    strict: false,
    total_retry: 1,
    total_questions: '',
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Link,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    exam_description: `
        <div>
            <h5>প্রিয় শিক্ষার্থী, মনযোগ সহকারে নিচের নির্দেশনাগুলো পড়োঃ</h5>
            <ul>
                <li>উক্ত পরীক্ষায় তুমি শুধুমাত্র ১ বার ই অংশগ্রহণ করতে পারবে।</li>
                <li>পরীক্ষা দেয়ার পর নির্দিষ্ট সময়ে উত্তরপত্র এবং রেংকিং দেখতে পারবে।</li>
                <li>যদি কোন প্রশ্ন, উত্তর কিংবা ব্যাখ্যায় তোমার চোখে ভুল ধরা পরে তবে প্রশ্নের পাশে থাকা ফ্ল্যাগ আইকনে ক্লিক করে দিবে এবং নির্দিষ্ট বক্সে তোমার মতামত জানাবে। কোন রেফারেন্স এর ছবি দিতে চাইলে Upload Photo বাটনে ক্লিক করে ছবি আপ্লোড দিতে পারো।</li>
                <li>শিক্ষক কতৃক প্রশ্ন সংশোধিত হলে তা সংশোধিত প্রশ্নসমূহ অপশানে পাবে। </li>
                <li>কোন প্রশ্ন তোমার কাছে গুরুত্বপূর্ণ মনে হলে কিংবা পরবর্তীতে আলাদাভাবে পড়তে চাইলে প্রশ্নের পাশে থাকা হার্ট আইকনে ক্লিক করে রাখবে এবং এই প্রশ্নগুলো পছন্দকৃত প্রশ্নসমূহ সেকশনে পাবে</li>
                <li>পরীক্ষায় ভুলকৃত প্রশ্নগুলো আলাদাভাবে ভুলকৃত প্রশ্নসমূহ সেকশনে দেখতে ও প্র্যাকটিস করতে পারবে, ধন্যবাদ।</li>
            </ul>
        </div>`,
    group_exa_description: `
    <div>
        <h5>প্রিয় শিক্ষার্থী, মনযোগ সহকারে নিচের নির্দেশনাগুলো পড়োঃ</h5>
        <ul>
        <li>উক্ত পরীক্ষায় তুমি শুধুমাত্র ১ বার ই অংশগ্রহণ করতে পারবে।কোন কোন বিষয়ের উপর পরীক্ষা দিতে চাও তা সিলেক্ট করে নিবে। পরীক্ষা শুরু হলে প্রতিটি বিষয় আলাদা ভাবে উত্তর করতে পারবে এবং সব দাগানো হয়ে গেলে Submit বাটনে ক্লিক করে পরীক্ষা সমাপ্ত করবে।</li>
        <li>পরীক্ষা দেয়ার পর নির্দিষ্ট সময়ে উত্তরপত্র এবং রেংকিং দেখতে পারবে।</li>
        <li>যদি কোন প্রশ্ন, উত্তর কিংবা ব্যাখ্যায় তোমার চোখে ভুল ধরা পরে তবে প্রশ্নের পাশে থাকা ফ্ল্যাগ আইকনে ক্লিক করে দিবে এবং নির্দিষ্ট বক্সে তোমার মতামত জানাবে। কোন রেফারেন্স এর ছবি দিতে চাইলে Upload Photo বাটনে ক্লিক করে ছবি আপ্লোড দিতে পারো। </li>
        <li>শিক্ষক কতৃক প্রশ্ন সংশোধিত হলে তা সংশোধিত প্রশ্নসমূহ অপশানে পাবে।</li>
        <li>কোন প্রশ্ন তোমার কাছে গুরুত্বপূর্ণ মনে হলে কিংবা পরবর্তীতে আলাদাভাবে পড়তে চাইলে প্রশ্নের পাশে থাকা হার্ট আইকনে ক্লিক করে রাখবে এবং এই প্রশ্নগুলো পছন্দকৃত প্রশ্নসমূহ সেকশনে পাবে</li>
        <li>পরীক্ষায় ভুলকৃত প্রশ্নগুলো আলাদাভাবে ভুলকৃত প্রশ্নসমূহ সেকশনে দেখতে ও প্র্যাকটিস করতে পারবে, ধন্যবাদ।</li>
        </ul>
    </div>`,
    practice_exam_description: `
    <div>
        <h5>প্রিয় শিক্ষার্থী, মনযোগ সহকারে নিচের নির্দেশনাগুলো পড়োঃ</h5>
        <ul>
            <li>উক্ত প্র্যাকটিস এক্সামে তুমি যতখুশি তত বার অংশ নিতে পারবে।যদিও সময়ের কোন বাধাধরা নেই তবুও চেষ্টা করবে প্রতিটি প্রশ্ন সর্বোচ্চ ৪৫ সেকন্ডে উত্তর করার।প্র্যাকটিসে তুমি যদি ৮০% এর উপর নাম্বার পাও তবে তোমার প্রস্তুতি ভাল আছে বলে ধরে নিতে পারো।</li>
            <li>যদি কোন প্রশ্ন, উত্তর কিংবা ব্যাখ্যায় তোমার চোখে ভুল ধরা পরে তবে প্রশ্নের পাশে থাকা ফ্ল্যাগ আইকনে ক্লিক করে দিবে এবং নির্দিষ্ট বক্সে তোমার মতামত জানাবে। কোন রেফারেন্স এর ছবি দিতে চাইলে Upload Photo বাটনে ক্লিক করে ছবি আপ্লোড দিতে পারো। </li>
            <li>শিক্ষক কতৃক প্রশ্ন সংশোধিত হলে তা সংশোধিত প্রশ্নসমূহ অপশানে পাবে। </li>
            <li>কোন প্রশ্ন তোমার কাছে গুরুত্বপূর্ণ মনে হলে কিংবা পরবর্তীতে আলাদাভাবে পড়তে চাইলে প্রশ্নের পাশে থাকা হার্ট আইকনে ক্লিক করে রাখবে এবং এই প্রশ্নগুলো পছন্দকৃত প্রশ্নসমূহ সেকশনে পাবে, ধন্যবাদ।</li>
        </ul>
    </div>
    `
  }),
  computed: {
    availableAt() {
      return moment(this.available_at).isValid() ? moment(this.available_at).format() : '';
    },
    endsAt() {
      return moment(this.ends_at).isValid() ? moment(this.ends_at).utc('utc').format() : '';
    },
    form: function () {
      let formData = new FormData();
      if (this.course) {
        formData.append('course_id', this.course.id);
      }
      if (this.editMode) {
        formData.append('_method', 'PUT')
      }
      if (!this.videoId){
        formData.append('topic_id', this.topic_id)
      } else {
        formData.append('video_id',this.videoId)
      }
      formData.append('title', this.title)
      formData.append('mode', this.mode)
      formData.append('description', this.description ? this.description : '')
      formData.append('privacy', this.privacy ? 'private' : 'public')
      formData.append('is_paid', this.is_paid ? 1 : 0)
      formData.append('available_at', this.availableAt)
      formData.append('pass_mark', this.pass_mark)
      formData.append('per_question_mark', this.per_question_mark)
      formData.append('negative_mark', this.negative_mark)
      formData.append('duration', this.duration)
      formData.append('ends_at', this.endsAt)
      formData.append('strict', this.strict ? 1 : 0)
      formData.append('total_retry', this.total_retry)
      if (this.total_retry > 1) {
        formData.append('total_questions', this.total_questions)
      }
      if (this.mode === 'group_exam') {
        formData.append('total_section', this.total_section)
      }
      return formData;
    }
  },
  methods: {
    takeAction() {
      this.editMode ? this.update() : this.submit();
    },
    edit(exam) {
      this.editMode = true;
      this.title = exam.title;
      this.description = exam.description;
      this.topic_id = exam.topic_id;
      this.link = exam.link;
      this.topic_id = exam.content.topic.id;
      this.selectedExam = exam;
      this.is_paid = exam.is_paid;
      this.strict = exam.strict === 1
      this.total_retry = exam.total_retry
      this.total_questions = exam.total_questions
    },
    submit() {
      const url = 'admin/exams';
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'New exam created';
        this.loadExams();
      })
    },
    update() {
      const url = 'admin/exams/' + this.selectedExam.id;
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'Exam updated';
        this.loadExams();
      })
    },
    loadExams() {
      this.$emit('updated');
    },
    clear() {
      this.title = ''
      this.total_section = null
      this.description = '';
      this.link = '';
      this.topic_id = '';
      this.selectedExam = null;
      this.available_at = '';
      this.pass_mark = ''
      this.per_question_mark = 1
      this.negative_mark = 0
      this.privacy = false
      this.strict = false
      this.total_retry = 1
      this.total_questions = ''
      if (this.mode === 'exam') {
        this.$refs.availableAtRef.clearHandler();
        this.$refs.endsAtRef.clearHandler();
      }
      this.$refs.observer.reset();
      this.duration = '';
    },
  },
  watch: {
    mode(value) {
      if (value === 'exam') {
        this.description = this.exam_description
      } else if (value === 'group_exam') {
        this.description = this.group_exa_description
      } else if (value === 'practice') {
        this.description = this.practice_exam_description
      }
    }
  },
  mounted() {
    this.description = this.exam_description
  }
}

setInteractionMode('eager');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})
</script>