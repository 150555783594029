<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          fab
          small
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
      >
        <v-icon>mdi-cloud-download</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-btn
            icon
            dark
            @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Import questions</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              dark
              text
              @click="dialog = false"
          >
            Back
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
          v-model="selected"
          :headers="headers"
          :items="indexedMcqs"
          :footer-props="footerProps"
          item-key="id"
          show-select
          class="elevation-1"
      >
        <template v-slot:item.question="{ item }">
          <div v-html="item.question" v-katex:auto></div>
        </template>
        <template v-slot:footer>
          <v-row>
            <v-col>
              <v-btn
                  color="primary"
                  class="mx-5 mt-4"
                  @click="download"
              >
                Download ({{ selected.length }} mcqs)
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3">
              <v-text-field
                  v-model="startIndex"
                  label="Start index"
                  class="pa-3"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  v-model="endIndex"
                  label="End index"
                  class="pa-3"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-title>
              <div>Choose questions</div>
            </v-toolbar-title>
            <v-row class="mt-2">
              <v-spacer></v-spacer>
              <v-col cols="3">
                <v-text-field
                    v-model="startIndex"
                    label="Start index"
                    class="pa-3"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    v-model="endIndex"
                    label="End index"
                    class="pa-3"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn
                    color="primary"
                    class="mx-5 mt-2"
                    @click="download"
                >
                  Download ({{ selected.length }} mcqs)
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  props: ['mcqs', 'exam_id'],
  data() {
    return {
      dialog: false,
      selected: [],
      startIndex: '',
      endIndex: '',
      headers: [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'Question',
          align: 'start',
          value: 'question',
        },
      ],
      footerProps: {
        itemsPerPageOptions: [20, 35, 50, 100, 500]
      },
    }
  },
  computed: {
    indexedMcqs() {
      this.mcqs.forEach((mcq, index) => {
        mcq.index = index + 1
      })
      return this.mcqs
    },
    selectedIds() {
      return this.selected.map(a => a.id)
    },
    form() {
      let formData = new FormData()
      if (this.exam_id) {
        formData.append('exam_id', this.exam_id)
      }
      formData.append('ids', JSON.stringify(this.selectedIds))
      return formData
    }
  },
  methods: {
    download() {
      const url = 'admin/mcqs/export'
      axios.post(url, this.form, {
        headers:
            {
              'Content-Disposition': "attachment; filename=template.xlsx",
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
        responseType: 'arraybuffer',
      }).then((response) => {
        console.log(response)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let exam_id = this.exam_id || this.uniqueId()
        link.setAttribute('download', exam_id + '.xlsx');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        console.log(error)
      })
    },
    selectMcqs() {
      this.selected = []
      this.indexedMcqs.forEach((mcq) => {
        if (this.startIndex <= mcq.index && (this.endIndex >= mcq.index || !this.endIndex)) {
          this.selected.push(mcq)
        }
      })
    }
  },
  watch: {
    startIndex() {
      if (this.startIndex) {
        this.selectMcqs()
      }
    },
    endIndex() {
      this.selectMcqs()
    }
  }
}
</script>